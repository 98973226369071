import React from "react";
import { Typography, Grid, Divider } from "@material-ui/core";
import myphoto from "../images/hema.png";


class AboutMe extends React.Component {
  myphoto = {
    display: "block",
    "margin-top": "5%",
    "margin-left": "auto",
    "margin-right": "auto",
    width: "40%",
  };
  headingName = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.6rem",
    "text-align": "center",
  };
  bodynext1 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.2rem",
    "text-align": "justify",
  };

  render() {
    return (
      <div>
        <Grid container>
          <Grid item xs={1} sm={2} md={3} lg={3}></Grid>
          <Grid item xs={10} sm={8} md={6} lg={6}>
            <img src={myphoto} style={this.myphoto} />
            <br />
            <Typography style={this.headingName}>
              Hemaramanan Sivanesan
            </Typography>
            <Typography variant="subtitle1" style={this.bodynext1}>
              <Divider />
              <br />
              Hi There!
              <br />
              <br />
              I am Hema, I've been a Network Engineer and Instructor for over 6+
              years, and I have worked with DC, ISP and Corporate networks.
              Further, I have a strong passion for network automation and
              developing tools for simplifying network maintenance and
              troubleshooting.
              <br />
              <br />I have developed a web-based tool called "<b>Pynetwork</b>",
              concentrated on network maintenance and troubleshooting. Further,
              I am working on adding more features to pynetwork.
              <br /> <br />
              Connect me on{" "}
              <b>
                <a href="https://www.linkedin.com/in/hema/" target="_blank">
                  LinkedIn
                </a>{" "}
              </b>
               , I'm <b>#OpenToWork </b> for
              exciting opportunities.
              <br />
              <br />
              This website only reflects my thoughts and interests, does not reflect or represent my current or previous workplaces directly or indirectly.
              <br />
              <br />
              Stay, Tuned! <br />
              <br />
              Regs, <br />
              Hema
            </Typography>
          </Grid>
          <Grid item xs={1} sm={2} md={3} lg={3}></Grid>
        </Grid>
      </div>
    );
  }
}

export default AboutMe;
