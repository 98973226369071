import React from "react";
import { Typography, Grid, Divider } from "@material-ui/core";
import myphoto from "../images/hema.png";

class Resume extends React.Component {
  myphoto = {
    display: "block",
    "margin-top": "5%",
    "margin-left": "auto",
    "margin-right": "auto",
    width: "40%",
  };
  heading1 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.7rem",
    "text-bold": "true",
  };
  heading2 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.4rem",
    "text-align": "justify",
  };
  bodynext1 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.3rem",
  };
  render() {
    return (
      <div>
        <Grid container>
          <Grid item xs={1} sm={2} md={3} lg={3}></Grid>
          <Grid item xs={10} sm={8} md={6} lg={6}>
            <br />
            <Typography style={this.heading1} noWrap>
              Hemaramanan Sivanesan
            </Typography>
            <Divider />
            <br />
            <Typography style={this.heading2}>
              I am a network engineer, I love Networking, Designing, Software
              Development and solving complex problems. Further, I build
              software tools to simplify network maintenance.
            </Typography>
            <br />
            <Divider />
            <br />
            <br />
            <Typography style={this.heading1} noWrap>
              Education
            </Typography>
            <Typography style={this.bodynext1}>
              <br />
              Reading, <br />
              <b>Master of Business Administration. </b>
              <br />
              <i>University of bedfordshire, (UK). </i> <br />
              <br />
              2016-2019 <br />
              <b>Bachelor of Technology in Computer Network Technology. </b>
              <br />
              <i>University of Vocational Technology, (Sri Lanka). </i> <br />
              Grade - Second Class upper (3.3/4).
            </Typography>
            <br />
            <br />
            <Divider />
            <br />
            <br />
            <Typography style={this.heading1} noWrap>
              Certifications
            </Typography>
            <Typography style={this.bodynext1}>
              <ul>
                <li>
                  <b>Cisco Certified DevNet Specialist</b>
                </li>
                <i>Service Provider Automation and Programmability</i>
                <br />
                Issued : 2021 , January.
                <br />
                <a
                  href="https://www.credly.com/badges/d8af3297-994a-419e-a0bd-5abca6812140/linked_in_profile"
                  target="_blank"
                >
                  Certification
                </a>
                <br />
                <br />
                <li>
                  <b>Docker Essentials: A Developer Introduction</b>
                </li>
                Issued : 2021 , January.
                <br />
                <a
                  href="https://www.credly.com/badges/2e79ad49-4315-4f4b-a9db-ac50061c5d19?source=linked_in_profile"
                  target="_blank"
                >
                  Course
                </a>
                <br />
                <br />
                <li>
                  <b>Cisco Certified DevNet Associate</b>
                </li>
                Issued : 2020 , July.
                <br />
                <a
                  href="https://www.credly.com/badges/fcbbd212-c24e-4368-8fbc-21517a5734d6/linked_in_profile"
                  target="_blank"
                >
                  Certification
                </a>
                <br />
                <br />
                <li>
                  <b>Nutanix Certified Professional 5</b>
                </li>
                Issued : 2020 , May.
                <br />
                <a
                  href="https://www.credly.com/badges/223774af-ac39-4b00-82f7-424b92b77628/linked_in_profile"
                  target="_blank"
                >
                  Certification
                </a>
                <br />
                <br />
                <li>
                  <b>Cisco Certified Meraki Network Associate</b>
                </li>
                Issued : 2020, March.
                <br />
                <a
                  href="https://community.meraki.com/t5/badges/userbadgespage/user-id/43522"
                  target="_blank"
                >
                  Training
                </a>
                <br />
                <br />
                <li>
                  {" "}
                  <b>Cisco Certified Network Professsional</b>
                </li>
                <i>(CCNP Routing & Switching)</i>
                <br />
                Issued : 2018 , August.
                <br />
                <a
                  href="https://www.credly.com/badges/2e86a682-ad15-42bf-98d9-33f81b5d3f31/linked_in_profile"
                  target="_blank"
                >
                  Certification
                </a>
                <br />
                <br />
                <li>
                  <b>Cisco Certified Network Associate</b>
                </li>
                <i>(CCNP Routing & Switching)</i>
                <br />
                Issued : 2016 , February.
                <br />
                <a
                  href="https://www.credly.com/badges/5e6f287a-8172-4f48-bedb-2757ec10c250/linked_in_profile"
                  target="_blank"
                >
                  Certification
                </a>
                <br />
                <br />
              </ul>
            </Typography>
            <Divider />
            <Typography style={this.heading1} noWrap>
              <br />
              Experience
            </Typography>
            <br />
            <Typography style={this.bodynext1}>
              <li>
                <b>Technical Specialist - Network and Automation</b>
              </li>
              <i>HCL Technologies, From:- 2021 May - Present</i> <br />
              <br />
              Working as Network TAC engineer for a corporate network which is
              distributed over 250 + locations over the glove with five
              datacenters includes 4000+ switches and routers. Further, Writing
              codes to network maintenance and troubleshooting.
              <br />
              <br />I am working with following Technologies / product.
              <ul>
                <li> Routing and Switching</li>
                <li> F5 Load Balancer - LTM</li>
                <li> SD-WAN - Meraki / Versa</li>
                <li> Wirless Network</li>
                <li> Palo Alto Firewall</li>
                <li> Python and Ansible for network automation.</li>
              </ul>
              <li>
                <b>Network and Automation Engineer</b>
              </li>
              <i>NCINGA PVT, From:- 2020, March. to 2021, May.</i>
              <br /> <br />
              Worked with multiple network technology and played various roles
              such as TAC Engineer, Network Deployment Engineer and Software
              developer.
              <br />
              <br />I had worked with the following Technologies/product.
              <ul>
                <li> Routing and Switching</li>
                <li> VXLAN and EVPN</li>
                <li> MPLS and Qos</li>
                <li> L2VPN / L3VPN</li>
                <li> Cisco EPNM (Data Center Network Manager).</li>
                <li>
                  {" "}
                  Cisco DCNM (Cisco Evolved Programmable Network Manager).
                </li>
                <li> Cisco Wirless Network.</li>
                <li> Docker and Microservices</li>
                <li> Elasticsearch, kibana , logstash (ELK Stack)</li>
                <li> Grafana</li>
                <li> Python and Ansible for network automation</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={1} sm={2} md={3} lg={3}></Grid>
        </Grid>
      </div>
    );
  }
}

export default Resume;
