import React from "react";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import ListItemText from "@material-ui/core/ListItemText";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import GitHubIcon from "@material-ui/icons/GitHub";
import AboutMe from "./AboutMe";
import AboutPynetwork from "./AboutPynetwork";
import Inventory from "./Inventory";
import WebCli from "./WebCli";
import ConfigManagement from "./ConfigManagement";
import Resume from "./Resume";
import Projects from "./Projects";
import AboutPynetworkProjects from "./AboutPynetworkProjects";
import HomeIcon from '@material-ui/icons//Home';
import QuickSetup from "./QuickSetup"

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  ButtonText: {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.2rem",
  },
  toolbarButtons: {
    marginLeft: "auto",
    display: "block",
  },
  ButtonGroupStyle: {
    marginRight: 10,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [displayPage, setDisplayPage] = React.useState(<AboutPynetwork />);

  const selectedPage = (index) => {
    console.log(index);
    if (index === "About Me") {
      setDisplayPage(<AboutMe />);
    }
    if (index === "pynetwork Intro") {
      setDisplayPage(<AboutPynetwork />);
    }
    if (index === "Inventory") {
      setDisplayPage(<Inventory />);
    }
    if (index === "WEB CLI") {
      setDisplayPage(<WebCli />);
    }
    if (index === "Config Management") {
      setDisplayPage(<ConfigManagement />);
    }
    if (index === "Resume") {
      setDisplayPage(<Projects />);
    }
    if (index === "Quick Setup") {
      setDisplayPage(<QuickSetup />);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = (event, index) => {
    // setOpen(!open)
    setSelectedIndex(index);
    selectedPage(index);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <ButtonGroup>
              <Button
                variant="contained"
                onClick={(event) => handleClick(event, "About Me")}
                style={{ "font-size": "0.8rem" }}
              >
                Myself
              </Button>
              {/* <Button
                variant="contained"
                onClick={(event) => handleClick(event, "Resume")}
                style={{ "font-size": "0.8rem" }}
              >
                Resume
              </Button> */}
            </ButtonGroup>

          <Typography variant="h6" noWrap align="center"></Typography>
          <div className={classes.toolbarButtons}>
          <IconButton
            color="inherit"
            href={"https://pynetwork.net/"}
            target="_blank"
          >
            <HomeIcon />
          </IconButton>        
          <IconButton
            color="inherit"
            href={"https://github.com/hemaramanan/"}
            target="_blank"
          >
            <GitHubIcon />
          </IconButton>
          
          <IconButton
            color="inherit"
            href={"https://www.linkedin.com/in/hema/"}
            target="_blank"
          >
            <LinkedInIcon />
          </IconButton>  
          {/* <IconButton
            color="inherit"
            href={
              "https://www.youtube.com/channel/UCNUC58PnjCGUWnpXXl3o2MA/featured"
            }
            target="_blank"
           
          >
            <YouTubeIcon/>
          </IconButton> */}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {["PYnetwork Doc"].map((text) => (
            <ListItem multiline key={text}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
          <List>
            {[
              "pynetwork Intro",
              "Quick Setup",
              "Inventory",
              "WEB CLI",
              "Config Management",
            ].map((text, index) => (
              <ListItem
                button
                key={text}
                selected={selectedIndex === text}
                onClick={(event) => handleClick(event, text)}
              >
                <ListItemText secondary={text} />
              </ListItem>
            ))}
          </List>
        </List>
        <Divider />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Grid container>
          <Grid item>{displayPage}</Grid>
        </Grid>
      </main>
    </div>
  );
}
