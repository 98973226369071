import React from "react";
import { Typography, Grid, Divider } from "@material-ui/core";
import PynetworkURL from "../images/PynetworkURL.png";

class WebCli extends React.Component {
  heading1 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.6rem",
    "text-bold": "true",
  };
  PynetworkURL = {
    display: "block",
    "margin-left": "auto",
    "margin-right": "auto",
    width: "100%",
  };
  preCode = {
    fontFamily: "monospace",
    "font-size": "1rem",
    "background-color": "#dbd6d6",
  };
  heading2 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.5rem",
  };
  bodynext1 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.2rem",
    "text-align": "justify",
  };
  iframecontainerYoutube = {
    position: "relative",
    width: "100%",
    "padding-bottom": "56.25%",
    height: "0",
  };
  iframeYoutube = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };

  render() {
    return (
      <div>
        <Grid container>
          <Grid item xs={1} sm={2} md={3} lg={3}></Grid>
          <Grid item xs={10} sm={8} md={6} lg={6}>
            <br />
            <br />
            <Typography style={this.heading1}>Quick Setup Guide</Typography>
            <Divider />
            <Typography style={this.bodynext1}>
              <br />
              This tutorial will cover the steps that need to run the pynetwork on your docker environment. 
              I'm using Linux as a host machine to spine-up the containers.  
              <br />
              <br />
              I have dockerized the pynetwork and hosted it on the Docker public registry (https://hub.docker.com/) as two images.  
              So, We have to use those two images to run the pynetwork services.
              <br />
              <ul>
                <li> hemaramanan/pynetwork_backend - It will cover the backend </li>
                <li>hemaramanan/pynetwork_frontend -It will cover the frontend </li>
              </ul>
              I'm going to use the docker-compose to spine-up these images (containers) and provision the service. 
              It will be easy to manage the pynetwork as a service rather than individually handle relevant containers to up and run the pynetwork.
             <br />
              <br />
              Follow the instruction below to run the pynetwork in your local environment.
            
              <br />
              <br />
              <b>Step: 1</b>
              <br/>
              Create the <b><i> docker-compose.yml </i> </b>file with the below-mentioned properties.
<pre style={this.preCode}>{`
  version: "3.9"
  services:
    pynetwork:
      image: hemaramanan/pynetwork_backend
      volumes:
        - /app/pynetwork/data/
      ports:
        - "5000:5000"
    pynetwork_ui:
      image: hemaramanan/pynetwork_frontend
      ports:
        - "8080:80"
      depends_on:
        - pynetwork

`}</pre>

              <b>Step: 2</b>
              <br/>
              Navigate to the particular folder which contained the <b><i>docker-compose.yml</i></b> file and run the <b><i>docker-compose up </i></b> command. while you enter that command, you may get some console output mentioned below.
<pre style={this.preCode}>{`
  root@ubs:/home/dev/pynetwork/test# ls
  docker-compose.yml
  root@ubs:/home/dev/pynetwork/test# 
  root@ubs:/home/dev/pynetwork/test# docker-compose up
  Creating network "test_default" with the default driver
  Creating test_pynetwork_1 ... done
  Creating test_pynetwork_ui_1 ... done
  Attaching to test_pynetwork_1, test_pynetwork_ui_1

`}</pre>
        
              Congratulations, you have successfully deployed the pynetwork on your local environment. Let's have fun with pynetwork!
              <br/> <br/>
              <b>Step: 3</b>
              <br/>
              Access the pynetwork on your web browser by using the IP address of the host machine with port 8080. In my example, it is <b><i>http://192.168.100.100:8080/ </i></b>
              <br/>
              <br/>
              <img src={PynetworkURL} style={this.PynetworkURL} />
              <br />
              <br />
              </Typography>
          </Grid>
          <Grid item xs={1} sm={2} md={3} lg={3}></Grid>
        </Grid>
      </div>
    );
  }
}

export default WebCli;
