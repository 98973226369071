import React from "react";
import { Typography, Grid, Divider } from "@material-ui/core";
import archImage from "../images/PynetworkArch.png";

class AboutPynetworkProjects extends React.Component {
  ArchPhoto = {
    display: "block",
    "margin-top": "5%",
    "margin-left": "auto",
    "margin-right": "auto",
    width: "100%",
  };
  heading1 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.6rem",
    "text-bold": "true",
  };
  heading2 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.5rem",
  };
  bodynext1 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.2rem",
    "text-align": "justify",
  };
  earlyDev = {
    margin: "22px 25% 22px",
  };

  render() {
    return (
      <div>
        <Grid container>
          <Grid item xs={1} sm={2} md={3} lg={3}></Grid>
          <Grid item xs={10} sm={8} md={6} lg={6}>
            <br />
            <br />
            <Typography style={this.heading1}>Hemaramanan Sivanesan</Typography>
            <Typography style={this.bodynext1}>
              <Divider />
              <br />
              I am a network engineer, I love Networking, Designing, Software
              Development and solving complex problems. Further, I build
              software tools to simplify network maintenance.
              <br />
              <br />
            </Typography>
            <Typography style={this.heading1} noWrap>
              Projects
              <Divider />
            </Typography>
            <br />
            <Typography style={this.bodynext1}>
              <ol>
                <li>
                  <b>Pynetwork - Web-Based Network Automation Tool.</b>
                </li>
                <br />
                Pynetwor is an open-source project that I developed and, I am
                working on it to add more features. Currently, It can run/push a
                list of show commands/configs on multiple devices (Cisco) via
                SSH. Further, It can manage the running configs backups of the
                device.
                <br />
                <br />
                Involved Technologies / products.
                <ul>
                  <li> React and Material-UI - Frontend.</li>
                  <li> Flask - Backend.</li>
                  <li> Sqlite3 - Database.</li>
                  <li> Netmiko - For interact with Network Devices.</li>
                </ul>
                <br />
                <li>
                  <b>Network Audit Tool.</b>
                </li>
                <br />
                I have developed network audit tools to analyse multiple
                functionalities, such as Node / Link redundancy, L3VPN / L2VPN,
                QoS, and specific config templates. To achieve this, I developed
                various Python modules to identity each service and
                functionality from running-config of each device in the network.
                Finally, I generated one excel report by combining all modules
                and that findings.
                <br />
                <br />
                Involved Technologies / products.
                <ul>
                  <li> Python</li>
                  <li> Data Structures and Algorithms</li>
                </ul>
                <br />
                <li>
                  <b>MPLS Based Netflow with ELK Stack. </b>
                </li>
                <br />
                This project is about collecting the MPLS Label-based NetFlow
                from the network and visualize/analyse it with the "ELK" stack.
                I had to do some modifications on the Logstach backend to
                identify the label-based Netflow. Also, extend the with Grafana.
                <br />
                <br />
                Involved Technologies / products.
                <ul>
                  <li> Docker</li>
                  <li> Elasticsearch</li>
                  <li> Logstash</li>
                  <li> Kibana</li>
                  <li> Grafana</li>
                </ul>
                <br />
                <li>
                  <b>Cisco NSO Deployment</b>
                </li>
                <br />
                I deployed Cisco NSO and create multiple service templates such
                as L2VPN/L3VPN with YANG Model. Further, I Used REST APIs to
                automate the NSO / Service Provisioning and used NETCONF for the
                South-Bound interface.
                <br />
                <br />
                Involved Technologies / products.
                <ul>
                  <li> Python</li>
                  <li> REST API</li>
                  <li> NETCONF</li>
                  <li> YANG</li>
                </ul>
                <br />
                <li>
                  <b>LNS Migration</b>
                </li>
                <br />
                In this project, I had to convert the IOS-XE configurations to
                IOS-XR configuration for migrating new LNS that based on IOS-XR.
                I wrote python codes to achieve this
                <br />
                <br />
                Involved Technologies / products.
                <ul>
                  <li> Python</li>
                  <li> YAML</li>
                </ul>
                <br />
                <li>
                  <b>Datacenter Deployment</b>
                </li>
                <br />
                I Involved in this project, from requirement collection to NRFU
                tests. I was part of the HLD, LLD and deployment of the project.
                Further, I prepared the entire NRFU document.
                <br />
                <br />
                Involved Technologies / products.
                <ul>
                  <li> VXLAN and EVPN</li>
                  <li> ISIS, BGP</li>
                  <li> Multicast, PIM, Anycast Gateway</li>
                  <li> VPC, VLAN, TRUNK, MCLAG , STP</li>
                  <li> Cisco Nexus Switches</li>
                </ul>
                <br />
                <li>
                  <b>Esxi and Shared Lab Deployment</b>
                </li>
                <br />
                I Involved in this project, from requirement collection to NRFU
                tests. I was part of the HLD, LLD and deployment of the project.
                Further, I prepared the entire NRFU document.
                <br />
                <br />
                Involved Technologies / products.
                <ul>
                  <li> VXLAN and EVPN</li>
                  <li> ISIS, BGP</li>
                  <li> Multicast, PIM, Anycast Gateway</li>
                  <li> VPC, VLAN, TRUNK, MCLAG , STP</li>
                  <li> Cisco Nexus Switches</li>
                </ul>
              </ol>
            </Typography>
          </Grid>
          <Grid item xs={1} sm={2} md={3} lg={3}></Grid>
        </Grid>
      </div>
    );
  }
}

export default AboutPynetworkProjects;
