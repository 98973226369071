import React from "react";
import { Typography, Grid, Divider } from "@material-ui/core";
import CreateBackup from "../images/CreateBackup.png";
import DeleteBackup from "../images/DeleteBackup.png";
import ExportBackup from "../images/ExportBackup.png";

class ConfigManagement extends React.Component {
  CreateBackup = {
    display: "block",
    "margin-left": "auto",
    "margin-right": "auto",
    width: "100%",
  };
  DeleteBackup = {
    display: "block",
    "margin-left": "auto",
    "margin-right": "auto",
    width: "100%",
  };
  ExportBackup = {
    display: "block",
    "margin-left": "auto",
    "margin-right": "auto",
    width: "100%",
  };
  heading1 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.6rem",
    "text-bold": "true",
  };
  heading2 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.5rem",
  };
  bodynext1 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.2rem",
    "text-align": "justify",
  };
  iframecontainerYoutube = {
    position: "relative",
    width: "100%",
    "padding-bottom": "56.25%",
    height: "0",
  };
  iframeYoutube = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };
  render() {
    return (
      <div>
        <Grid container>
          <Grid item xs={1} sm={2} md={3} lg={3}></Grid>
          <Grid item xs={10} sm={8} md={6} lg={6}>
            <br />
            <br />
            <Typography style={this.heading1}>
              Configuration and Backup Management
            </Typography>
            <Divider />
            <Typography style={this.bodynext1}>
              <br />
              This feature is managing the backup of the running-config of devices. We can Backup multiple node running configurations at once. Also, 
              we can delete the unwanted backup from the database and keep the latest backup. 
              further, we can export a set of config files from pynetwork in ZIP format.
              <br />
              <br />
              <b> Create Backup </b>
              <br/>
        
              This feature allows to backup the running configuration of one or more nodes on the pynetwork. 
              In this example, we did backup the running configurations parallelly on two switches. (<b>"192.168.200.101" </b> & <b>"192.168.200.102"</b>).
              <br/><br/>
              <img src={CreateBackup} style={this.CreateBackup} />
              <br/><br/>

              <b> Delete Backup </b>
              <br/>
        
              The config backup will be stored inside the pynetwork, This feature allows to delete the unwanted/outdated configs from pynetwork.

              <br/><br/>
              <img src={DeleteBackup} style={this.DeleteBackup} />
              <br/><br/>
              <b> Export Backup </b>
              <br/>
        
              This feature allows to export a set of configuration backup files from pynetwork in ZIP format.

              <br/><br/>
              <img src={ExportBackup} style={this.ExportBackup} />
              <br/><br/>
            </Typography>
          </Grid>
          <Grid item xs={1} sm={2} md={3} lg={3}></Grid>
        </Grid>
      </div>
    );
  }
}

export default ConfigManagement;
