import React from "react";
import { Typography, Grid, Divider } from "@material-ui/core";
import SHowCLI from "../images/SHowCLI.png";
import ConfigCli from "../images/ConfigCli.png";

class WebCli extends React.Component {
  heading1 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.6rem",
    "text-bold": "true",
  };
  heading2 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.5rem",
  };
  bodynext1 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.2rem",
    "text-align": "justify",
  };
  iframecontainerYoutube = {
    position: "relative",
    width: "100%",
    "padding-bottom": "56.25%",
    height: "0",
  };
  iframeYoutube = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };
  SHowCLI = {
    display: "block",
    "margin-left": "auto",
    "margin-right": "auto",
    width: "100%",
  };
  
  ConfigCli = {
    display: "block",
    "margin-left": "auto",
    "margin-right": "auto",
    width: "100%",
  };

  render() {
    return (
      <div>
        <Grid container>
          <Grid item xs={1} sm={2} md={3} lg={3}></Grid>
          <Grid item xs={10} sm={8} md={6} lg={6}>
            <br />
            <br />
            <Typography style={this.heading1}>Web based CLI</Typography>
            <Divider />
            <Typography style={this.bodynext1}>
              <br />
              This section will cover the WEB CLI feature of pynetwork. Web CLI
              allows you to perform the commands-execution on the network
              devices that have already been added with the pynetwork database.
              It is providing a web-based CLI console to manage your network.
              Also, you can execute the same command on multiple devices at the
              same time.
              <br />
              <br />
              There are two types of WEB CLI is available in pynetwork, those
              are "Show CLI" and another "Config CLI".
              <ul>
                <li> Show CLI - Execute show commands on multiple nodes.</li>
                <li>
                  {" "}
                  Config CLI - Execute config commands on multiple nodes.
                </li>
              </ul>
              <b> Show CLI </b>
              <br/>
        
              This feature allows running multiple show commands on one or more nodes on the pynetwork. In this example, 
              we could able to execute two commands (<b>"show version | in uptime"</b> & <b>"show ip interface brief | in up"</b> ) parallelly on two switches (<b>"192.168.200.101" </b> & <b>"192.168.200.102"</b>).
              <br/><br/>
              <img src={SHowCLI} style={this.SHowCLI} />
              <br/><br/>
              <b> Config CLI </b>
              <br/>
        
              This feature allows to execute configurations on one or more nodes on the pynetwork. In this example, 
              we could able to apply set of configurations (<b>"ip name-server 10.25.10.5"</b> & <b>"ip name-server 10.25.10.6"</b> ) parallelly on two switches (<b>"192.168.200.101" </b> & <b>"192.168.200.102"</b>).
              <br/><br/>
              <img src={ConfigCli} style={this.ConfigCli} />
            </Typography>
          </Grid>
          <Grid item xs={1} sm={2} md={3} lg={3}></Grid>
        </Grid>
      </div>
    );
  }
}

export default WebCli;
