import React from "react";
import { Typography, Grid, Divider } from "@material-ui/core";

class Inventory extends React.Component {
  heading1 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.6rem",
    "text-bold": "true",
  };
  heading2 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.5rem",
  };
  bodynext1 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.2rem",
    "text-align": "justify",
  };
  iframecontainerYoutube = {
    position: "relative",
    width: "100%",
    "padding-bottom": "56.25%",
    height: "0",
  };
  iframeYoutube = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };

  render() {
    return (
      <div>
        <Grid container>
          <Grid item xs={1} sm={2} md={3} lg={3}></Grid>
          <Grid item xs={10} sm={8} md={6} lg={6}>
            <br />
            <br />
            <Typography style={this.heading1}>Inventory Management</Typography>
            <Divider />
            <Typography style={this.bodynext1}>
              <br />
              Inventory is managing the IP address and credentials of the
              network devices, That information will be used by pynetwork to
              interact with the device and perform operational tasks such as
              sending show commands or config commands.
              <br />
              <br />
              In this section, we need to look at the two API flows that listed
              below.
              <ul>
                <li>
                  {" "}
                  GET all the device records from the pynetwork database.
                </li>
                <li>
                  {" "}
                  Managing the credentials database with pynetwork by DELETE /
                  UPDATE / CREATE operations.
                </li>
              </ul>
              Mentioned APIs are working on the backend, and the frontend is
              utilizing it according to the user inputs.
              <br />
              <br />
              Check the demo and get an understanding of the inventory feature
              and the APIs flows.
              <br />
              <br />
              <div style={this.iframecontainerYoutube}>
                <iframe
                  style={this.iframeYoutube}
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/CgQ_LJyrYlo"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <br />
              <br />
            </Typography>
          </Grid>
          <Grid item xs={1} sm={2} md={3} lg={3}></Grid>
        </Grid>
      </div>
    );
  }
}

export default Inventory;
