import React from "react";
import { Typography, Grid, Divider } from "@material-ui/core";
import archImage from "../images/PynetworkArch.png";

class AboutPynetwork extends React.Component {
  ArchPhoto = {
    display: "block",
    "margin-top": "5%",
    "margin-left": "auto",
    "margin-right": "auto",
    width: "100%",
  };
  heading1 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.6rem",
    "text-bold": "true",
  };
  heading2 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.5rem",
  };
  bodynext1 = {
    fontFamily: ["Corbel"].join(","),
    "font-size": "1.2rem",
    "text-align": "justify",
  };
  earlyDev = {
    margin: "22px 25% 22px",
  };
  iframecontainerYoutube = {
    position: "relative",
    width: "100%",
    "padding-bottom": "56.25%",
    height: "0",
  };
  iframeYoutube = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };

  render() {
    return (
      <div>
        <Grid container>
          <Grid item xs={1} sm={2} md={3} lg={3}></Grid>
          <Grid item xs={10} sm={8} md={6} lg={6}>
            <br />
            <br />
            <Typography style={this.heading1}>
              Introduction to Pynetwork
            </Typography>
            <Typography style={this.bodynext1}>
              <Divider />
              <br />
              Pynetwork is an <b>#OpenSourced</b> network automation tool with Web Interface for simplifying network maintenance and troubleshooting. Currently, 
              it supports CLI based automation (for Cisco devices), in future, I am planning to add more features with NETCONF and RESTCONF capability.
              <br />
              <br />

              Check out the <b>Quick Setup</b> section to set up the pynetwork in your local environment.

              <br />
              <br />
            </Typography>
            <Typography style={this.heading2}>
              Pynetwork Architecture
            </Typography>
            <Divider />
            <img src={archImage} style={this.ArchPhoto} />

            <Typography style={this.bodynext1}>
              <br />
              This is the simple architecture of pynetwork, let's look at each
              part in a little bit of detail. Further, watch my introduction
              session about pynetwork at <b> Cisco DevNet Create 2021.</b>
              <br />
              <br />
              <div style={this.iframecontainerYoutube}>
                <iframe
                  style={this.iframeYoutube}
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/cN_tu2juedQ"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <br />
              <br />
              <b>Frontend</b>
              <Divider />
              <br />
              The frontend is the user interacting WEB GUI interface and it is
              doing two main jobs.
              <ul>
                <li>
                  {"  "}
                  Collect the data from the user and send it back to the backend
                  for processing that data.{" "}
                </li>
                <li>
                  {" "}
                  Get the response (Processed data) from the backend and display
                  it on the WEB GUI.
                </li>
              </ul>
              I used React and material-UI for developing the frontend. React is
              a framework for developing the frontend ( developed by Facebook).
              further, Material-UI is prebuilt react components, so we can
              directly import and use it with React.
              <br /> <br />
              For Example:-
              <dl>
                <dt> What needs to appear? </dt>
                <dd> Typography- is the components for handling text </dd>
                <dt> How does it need to appear? </dt>
                <dd> Variant:'h1' - Type/size of the text is Heading1 </dd>
              </dl>
              <br />
              <b>Backend</b>
              <Divider />
              <br />
              I used Flask for the backend, which is getting the request from
              the user via the frontend and process that responds then send back
              that processed data to the frontend. The frontend will display the
              data to the user according to the frontend design.
              <br />
              <br />
              <b>Netmiko </b>
              <br />
              It’s a python library for interacting with network devices. For
              example, if I need to get the output of the "show version | in
              uptime" command on a device, So I can use the function called
              "send_commands" to interact with the device with show commands.
              likewise, it contains a separate function for configuring the
              devices.
              <br />
              <br />
              <b>Sqlalchemy and Sqlite3 </b>
              <br />
              It's an ORM (Object-relational mapper) library and it can interact
              with the Database without any SQL queries and the Sqlite3 is the
              database we are using. For example, if I need to get one-row data,
              I need to use the "filter_by" function with some arguments instead
              of SQL Query like "SELECT * FROM &lt;TableName&gt; WHERE
              &lt;Condition&gt;". It was very easy to interact with the database
              with SQLALhemy and I am sharing this from my experience.
            </Typography>
          </Grid>
          <Grid item xs={1} sm={2} md={3} lg={3}></Grid>
        </Grid>
      </div>
    );
  }
}

export default AboutPynetwork;
